angular.module('fingerink')
    .directive('signaturesSentDashboardWidget', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'web/main/dashboard/widgets/signaturesSent.tpl.html',
            scope: {

            },
            controllerAs: 'controller',
            controller: 'signaturesSentDashboardWidgetCtrl'
        };
    })
    .controller('signaturesSentDashboardWidgetCtrl', function ($rootScope, $scope, session) {
        var that = this;
        that.session = session.get();

        that.product = {};
        that.plan = that.session.plan;
        that.serviceStates = {};
        that.credits = session.getRemainingCredits();
        
        if (that.session.plan && that.session.plan.orderPeriods && that.session.plan.orderPeriods[0] && that.session.plan.orderPeriods[0].orderPeriodStates && that.session.plan.orderPeriods[0].orderPeriodStates[0] ) {
            that.product = that.session.plan.orderProduct;
            that.plan = that.session.plan;
            that.serviceStates = that.session.plan.orderPeriods[0].orderPeriodStates[0];
        }
    });
